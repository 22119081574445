import React from "react";
import NavMenuRequestDemo from "../components/NavMenuRequestDemo";

import imageProductMenu from "../images/product_menu.webp";
import imageSolutionsMenu from "../images/solutions_menu.webp";
import imageMenuResource from "../images/menu_resource.webp";
import imageAboutMenu from "../images/about_menu.webp";
import imageWorldWide from "../images/worldwide.webp";
import imageOnlineCoRoundCorder from "../images/imageonline-co-roundcorner.webp";

export const menuItems = [
  {
    name: "entomo",
    component: "entomo",
    title: "entomo",
    description: "modern performance management software built for future of work",
    url: "/all-products/entomo/",
    img: imageProductMenu,
    action: {
      name: "View All",
      url: "/all-products/entomo/",
    },
    sub: [
      {
        name: "perform",
        description: "measure and drive performance, everyday",
        url: "/all-products/entomo/#perform",
      },
      {
        name: "engage",
        description: "drive engagement within and across teams",
        url: "/all-products/entomo/#engage",
      },
      {
        name: "nudge",
        description: "nudge with Insights personalized to individual and team goals",
        url: "/all-products/entomo/#nudge",
      },
      {
        name: "act",
        description: "manage distributed and remote teams",
        url: "/all-products/entomo/#act",
      },
      {
        name: "grow",
        description: "build a tomorrow-ready workforce",
        url: "/all-products/entomo/#grow",
      },
      {
        name: "features",
        url: "/all-products/features/",
      },
    ],
  },
  {
    name: "solutions",
    component: "solutions",
    title: "solutions",
    description: "prepare your organization for the future, you wish to create",
    url: "/solutions/",
    img: imageSolutionsMenu,
    action: {
      name: "View All",
      url: "/solutions/",
    },
    sub: [
      {
        name: "everyday performance",
        url: "/solutions/everyday-performance/",
      },
      {
        name: "everyday insights",
        url: "/solutions/everyday-insights/",
      },
      {
        name: "careers tomorrow",
        url: "/solutions/careers-tomorrow/",
      },
      {
        name: "first safety",
        url: "/solutions/workplace-safety/",
      },
    ],
  },
  {
    name: "inspiration",
    component: "inspiration",
    title: "inspiration",
    description: "industry insights, inspiring stories, innovative ideas – everything in one place.",
    img: imageMenuResource,
    action: {
      name: "View All",
      url: "/resources/",
    },
    sub: [
      {
        name: "blog",
        url: "/resources/blog/",
      },
      {
        name: "e books & whitepapers",
        url: "/resources/whitepaper-e-book/",
      },
      {
        name: "success stories",
        url: "/resources/success-stories/",
      },
      {
        name: "entomo community",
        url: "/entomo-community/",
      },
    ],
  },
  {
    name: "about",
    component: "about",
    title: "why entomo",
    description: "want to know more about entomo ? You have come to the right place.",
    img: imageAboutMenu,
    sub: [
      {
        name: "why entomo",
        url: "/about/why-entomo/",
      },
      {
        name: "our team",
        url: "/about/our-team/",
      },
      {
        name: "news & press releases",
        url: "/news-press-releases/",
      },
    ],
  },
  {
    name: "contact",
    component: "contact",
    url: "/contact/",
  },
  {
    name: "request demo",
    component: <NavMenuRequestDemo text="request demo" url={"/request-demo/"} />,
  },
  {
    name: "regional presense",
    component: <img src={imageWorldWide} alt="worldwide" className="h-6 lg:h-7" />,
    title: "regional presence",
    description: "regional offerings are tailor made to incorporate region wise requirements",
    img: imageOnlineCoRoundCorder,
    sub: [
      {
        name: "Middle East & Africa",
        url: "/en-mea/",
      },
      {
        name: "Indonesia",
        url: "/id/",
      },
      {
        name: "Japanese",
        url: "/jp/",
      },
      {
        name: "United States",
        url: "/us/",
      },
    ],
  },
];
