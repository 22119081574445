import React from "react";
import { Link } from "gatsby";
import Container from "../../../utils/Container";
import { regions, innovationCenters, solutions, inspirations } from "../../../constants/footer-global";
import { getCopyright, getSocialMediaLink } from "../../../utils";

const Footer = () => {
  return (
    <footer>
      <Container className=" max-w-[125rem] mx-aut0 mt-[9%]">
        <div>
          <div className=" h-8 w-32 lg:w-52">
            <img src="https://entomo.co/wp-content/uploads/2021/02/001_Entomo_Logo_Colour.svg" alt="logo" />
          </div>
        </div>
        <h2 className=" font-extrabold text-2xl pt-10 ">regional headquarters</h2>
        <div className="sm:block lg:flex justify-items-start mt-4 ">
          <div className="w-full lg:w-2/5">
            <div>
              <div className="grid md:grid-cols-2 sm:grid-col-1 gap-7 pb-10 w-2/3 lg:w-full">
                {regions.map((region) => {
                  return (
                    <div className="text-[#333333] text-base font-medium" key={region.id}>
                      <h3>{region.country}</h3>
                      <div className="text-[#4d4d4d] text-xs xl:text-sm font-normal leading-7">
                        {region.addressLines.map((addressLine) => {
                          return (
                            <p key={addressLine} className="text-[#4d4d4d]">
                              {addressLine}
                            </p>
                          );
                        })}
                        <a href={region.tel}>{region.tel}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <h2 className="font-extrabold text-2xl">innovation centers</h2>
              <div className="grid md:grid-cols-2 sm:grid-col-1 gap-7 pb-10 w-2/3 lg:w-full">
                {innovationCenters.map((innovationCenter) => {
                  return (
                    <div className="text-[#333333] text-base  mt-4 font-medium" key={innovationCenter.id}>
                      <h3>{innovationCenter.country}</h3>
                      <div className="text-[#4d4d4d] text-xs xl:text-sm font-normal leading-7">
                        {innovationCenter.addressLines.map((addressLine) => {
                          return <p key={addressLine}>{addressLine}</p>;
                        })}
                        <a href={innovationCenter.tel}>{innovationCenter.tel}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="grid lg:block grid-cols-1 gap-9 md:col-span-1 mb-8">
              {solutions.map((solution) => {
                return (
                  <div className="text-[#333333] text-base font-medium lg:px-4" key={solution.id}>
                    <h3>{solution.title}</h3>
                    <div className="text-[#4d4d4d] text-xs xl:text-sm font-normal lg:mb-8">
                      {solution.contents.map((content) => {
                        return (
                          <Link to={content.link} key={content.text}>
                            <p className=" md:py-1 break-normal leading-6">{content.text}</p>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid md:grid-cols-2 sm:grid-col-1 gap-9 md:col-span-2">
              {inspirations.map((inspiration) => {
                return (
                  <div className="text-[#333333] text-base mb-1 font-medium  sm:px-4" key={inspiration.id}>
                    <h3>{inspiration.title}</h3>
                    <div className="text-[rgb(77,77,77)] text-xs xl:text-sm font-normal break-normal">
                      {inspiration.contents.map((content) => {
                        return (
                          <Link to={content.link} key={content.text}>
                            <p className=" md:py-1 break-normal leading-6">{content.text}</p>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" my-12">{getSocialMediaLink(["facebook", "twitter", "linkedin", "youtube", "instagram"])}</div>
      </Container>
      {getCopyright()}
    </footer>
  );
};

export default Footer;
