import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import Header from "../../Header";
import Footer from "./Footer";
import Redirection from "../../../components/Redirection";
import ContactWhatsApp from "../../../components/ContactWhatsApp";
import { menuItems } from "../../../constants/header-global-menu-items";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryGlobal {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} menuItems={menuItems} />
      {/* margin top = height of header : h-[95px] lg:h-[115px] xl:h-[140px] 2xl:h-[150px] */}
      <div className="mt-[95px] lg:mt-[115px] xl:mt-[140px] 2xl:mt-[150px] max-w-[1920px] mx-auto">{children}</div>
      <Footer />
      <ContactWhatsApp />
      <Redirection />
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
